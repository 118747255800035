// Chameleon is a touring software requested by Product
// More information can be found in MC-15245
// For more information on chameleon go to https://www.trychameleon.com/

!(function (t, n, o) {
  var a = 'chmln',
    e = 'adminPreview',
    c = 'setup identify alias track set show on off custom help _data'.split(
      ' '
    )
  if ((n[a] || (n[a] = {}), n[a][e] && (n[a][e] = !1), !n[a].root)) {
    ;(n[a].accountToken = o),
      (n[a].location = n.location.href.toString()),
      (n[a].now = new Date())
    for (var s = 0; s < c.length; s++)
      !(function () {
        var t = (n[a][c[s] + '_a'] = [])
        n[a][c[s]] = function () {
          t.push(arguments)
        }
      })()
    var i = t.createElement('script')
    ;(i.src = 'https://fast.trychameleon.com/messo/' + o + '/messo.min.js'),
      (i.async = !0),
      t.head.appendChild(i)
  }
})(
  document,
  window,
  'SJ1bGb479SBZl7pPQSCPJT3wwWw6FJHh6wgsC7j7eNY3xx-1EOV7d-ArSKn5FoTrtMldBx'
)
/**
 * The following code depends on the current_teacher variable which is set in
 * app/views/layouts/_current_teacher_setup.html.erb
 */
try {
  var name = current_teacher.first_name + ' ' + current_teacher.last_name
  chmln.identify(current_teacher.id, {
    // Unique ID of each user in your database (e.g. 23443 or "590b80e5f433ea81b96c9bf6")
    email: current_teacher.email, // Put quotes around text strings (e.g. "jim@example.com")
    created: current_teacher.created_at, // Send dates in ISO or unix timestamp format (e.g. "2017-07-01T03:21:10Z" or 1431432000)
    name: name, // We will parse this to extra first and surnames (e.g. "James Doe")
    role: current_teacher.roles, // Send properties useful for targeting types of users (e.g. "Admin")

    // Optional - company properties:
    company: {
      uid: current_teacher.district.id,
      created: current_teacher.district.created_at,
      name: current_teacher.district.name,
      plan: current_teacher.district.access_type,
    },
  })
} catch (error) {
  console.log(error)
}
