import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'

const ENV = window.RAILS_ENV
const SENTRY_DSN = window.SENTRY_JS_DSN

let initialized = false

const SentrySetup = () => {
  if (!initialized) {
    initialized = true
    if (['staging', 'production'].includes(ENV)) {
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
          new BrowserTracing(),
          new CaptureConsole({
            levels: ['error'],
          }),
        ],
        tracesSampleRate: 0.2,
        environment: ENV,
      })
    }
  }
}

export default SentrySetup