/**
 * This file imports the essential dependencies required for the pure React application specified in `pure_react.html.erb`.
 * It parallels `setup/modern_global.ts`, excluding 'setup/instui' from that file, which is not needed for this context.
 * As such, this file acts as a replacement for `modern_global.js`, tailored for use with the globals in the `pure_react.html.erb` layout.
 */
import 'setup/load_fonts'
import SentrySetup from '../mc-sdk/sentry'
import 'setup/chameleon'

SentrySetup()
